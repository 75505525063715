import {useContext, useEffect, useMemo, useState} from "react";
import {CreateCartoCss, CreateCartoLayer, CreateCartoQuerySource, GetLeafletLayer} from "../../../manager/carto";
import {DataContext} from "../../index";
import PropTypes from "prop-types";
import style1 from "./style1"
import style2 from "./style2"


const MapTrafficLayer = (props) => {


    const getQuery = (date, slot, style) => {
        let sNum = (slot ?? 0) + 1

        if (style !== "style2") {
            return `
        SELECT 
        demo_highway.objectid as cartodb_id,
        the_geom_webmercator,
        roadclass_c,
        linkclass_c,
        spd[${sNum}] as spd
        FROM takuman.demo_highway
        LEFT JOIN takuman.highway_spd
        ON demo_highway.objectid = highway_spd.objectid
        WHERE roadclass_c = 101
        `
        } else {
            return `
            SELECT * FROM demo_highway
            WHERE slot = ${sNum}
            `
        }
    }

    const { state } = useContext(DataContext)
    const [cartoSource, setCartoSource] = useState(null)
    const cartoCss = useMemo(() => CreateCartoCss(state.style === "style2" ? style2 : style1), [])

    useEffect(() => {
        if (!state.date) { return null}

        let query = getQuery(state.date, state.timeSlot, state.style)
        console.log('[TrafficLayer]', 'query', query)
        if (!query) { return }

        if (!cartoSource) {
            let source = CreateCartoQuerySource(query)
            setCartoSource(source)
        } else {
            cartoSource.setQuery(query)
        }
    }, [state.date, state.timeSlot, state.style])

    useEffect(() => {
        if (!state.style) { return }
        switch(state.style) {
            case "style1":
                cartoCss.setContent(style1)
                break
            case "style2":
                cartoCss.setContent(style2)
                break
        }
    }, [state.style])

    let cartoLayer = useMemo(() => {
        if (!props.map || !cartoSource) { return null }
        return CreateCartoLayer(cartoSource, cartoCss)
    }, [props.map, cartoSource])

    let layer = useMemo(() => {
        if (!props.map || !cartoLayer) { return null}
        let l = GetLeafletLayer(cartoLayer)
        l.setZIndex(1000)
        l.addTo(props.map)
        return l
    }, [props.map, cartoLayer])

    useEffect(() => {
        return () => {
            console.log("remove traffic layer")
        }
    }, [])

    return null
}

MapTrafficLayer.propTypes = {
    map: PropTypes.any,
}

export default MapTrafficLayer
