import React, {useEffect, useState} from "react"
import {Box, Typography} from "@mui/material";
import {DataContext} from "../../index";
import {useContext} from "react";
import {sprintf} from "sprintf-js";
import PropTypes from "prop-types";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    root: {
        padding: "18px",

    },
    label: {
        "-webkit-text-stroke": "1.6px #7d7678",
        textStroke: "1.6px #7d7678",
        color: "#dddddddd",
        fontSize: "46px !important",
        fontWeight: "700 !important",
        fontFamily: "monospace",
    }
})

const DateLabelWidget = (props) => {
    const classes = useStyles()
    const { state } = useContext(DataContext)
    const [label, setLabel] = useState(null)

    useEffect(() => {
        if (!state.date) { return }

        let hour = Math.floor((state.timeSlot ?? 0) / 60)
        let min = state.timeSlot - (hour * 60)
        let time = sprintf("%02d", hour) + "時" + sprintf("%02d", min) + "分"
        setLabel(`${state.date.format("YYYY年MM月DD日(dd)")} ${time}`)

    }, [state.date, state.timeSlot])

    return (
        <Box className={classes.root + " " + props.className} style={props.style}>
            <Typography className={classes.label}>{label}</Typography>
        </Box>
    )

}

DateLabelWidget.propTypes = {
    className: PropTypes.string,
    style: PropTypes.any,
}

export default DateLabelWidget
